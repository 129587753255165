<template>
    <div class="menuCss">
        <div :class="'navigation-content'" ref="navigationContent" >
                <div v-for="menupart in menus" :key="menupart.label">
                    <h4 v-show="menupart.label != ''" class="mx-2 menu">{{$t(menupart.label)}}</h4>
                    <v-list dense nav class="pa-0" color="primary">
                        <template v-for="(menu) in menupart.menus">
                            <v-menu
                                v-if="menu.sub"
                                open-on-hover
                                right
                                offset-x
                                :key="menu.title"
                                content-class="submenu"
                                style="z-index:2500;"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item
                                    mandatory
                                    active-class="active-menu"
                                    class=" pt-1"
                                    :class="menu.activeRoute && menu.activeRoute.includes($route.name) ? 'active-menu' : ''"
                                    v-bind="attrs"
                                    v-on="on"
                                    :to="menu.name != '' ? {name: menu.sub[0].name} : ''"
                                    :exact-path="menu.name == 'Home' ? true : false"
                                    link
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title class="primarytext--text menuBtn">
                                                <v-icon size="26" color="primarytext">${{menu.icon}}</v-icon>
                                                <span v-if="!menu.count">{{ $t(menu.title) }}</span>
                                                <v-badge v-if="menu.count" class="badge-nb mt-0" color="error" :content="menu.count" offset-x="30" offset-y="-15">
                                                    {{ $t(menu.title) }}
                                                </v-badge>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>

                                <v-list color="primary lighten-1">
                                        <v-list-item
                                        v-for="(item, index) in menu.sub"
                                        :key="index"
                                        dense
                                        :class="(item.activeRoute && item.activeRoute.includes($route.name)) || (item.query && _.isEqual(item.query, $route.query)) ? 'active-submenu mx-2' : 'mx-2'"
                                        :to="item.name != '' ? {name: item.name, query: (item.query ? item.query : {})} : ''"
                                        link
                                        >
                                        <v-list-item-title class="white--text">{{ $t(item.title) }}</v-list-item-title>
                                        </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-list-item
                            mandatory
                            v-else
                            active-class="active-menu"
                            class=" pt-1"
                            :to="menu.name != '' ? {name: menu.name} : ''"
                            :exact-path="menu.name == 'Home' ? true : false"
                            link
                            :key="'nosub_'+menu.title"
                            >
                                <v-list-item-content>
                                    <v-list-item-title class="primarytext--text menuBtn">
                                        <v-icon size="26" color="primarytext">${{menu.icon}}</v-icon>
                                        <span v-if="!menu.count">{{ $t(menu.title) }}</span>
                                        <v-badge v-if="menu.count" class="badge-nb mt-0" color="error" :content="menu.count" offset-x="30" offset-y="-15">
                                            {{ $t(menu.title) }}
                                        </v-badge>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        
                    </v-list>
                </div>

                <div v-if="this.$store.state.auth.user.admin_type && this.$store.state.auth.user.admin_type === '1'">
                    <h4 class="mx-2 menu">Test</h4>
                    <v-list dense nav color="primary">
                        <v-list-item mandatory color="primary" active-class="active-menu" to="/nico-test" link>
                            <v-list-item-content>
                                <v-list-item-title class="primarytext--text">Nico Test</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item mandatory color="primary" active-class="active-menu" to="/tibo-test" link>
                            <v-list-item-content>
                                <v-list-item-title class="primarytext--text">Tibo Test</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item mandatory color="primary" active-class="active-menu" to="/dono-test" link>
                            <v-list-item-content>
                                <v-list-item-title class="primarytext--text">Dono Test</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>
        </div>
    </div>
</template>
<script>
import SearchMenu from '@/components/SearchMenu'
export default {
    name:"Menu",
    components: {SearchMenu},
    data () {
      return {
        //mini: false,
        drawer: true,
        css: "",
        publicPath: process.env.BASE_URL,
        innerWidth: window.innerWidth,
        subPage: ""
      }
    },
    mounted() {
        this.css = 'height:'+this.$refs.navigationContent.getBoundingClientRect().height+"px";
        //this.mini = this.$store.state.auth.currentUser.parameters.menu_toggle;
    },
    computed: {
        ratio: function(){
            return window.devicePixelRatio
        },
        menus: function(){
            return this.$store.state.base.menu
        }
    },
    methods: {
        goTo: function(menu){
            if(menu.sub){
                this.$store.dispatch('base/SET_SUB_MENU', menu.sub)
            } else {
                this.$store.dispatch('base/SET_SUB_MENU', [])
            }
        }
    },
}
</script>

<style lang="scss">
    .submenu {
        border-radius:0 4px 4px 0 !important;
        .v-list-item__title {
            z-index:2;
            color:white;
        }
        .v-list-item:not(.v-list-item--active):hover::before {
            border-radius:0px !important;
            opacity:1!important;
            background-color: rgba(0,0,0,0.2) !important;
        }
    }
    .v-list-item--active {
        &::before {
            border-radius:4px !important;
            opacity:0!important;
        }
    }
    .active-submenu {
        
        .v-list-item__title {
            z-index:2;
            color:white;
        }
            
        &::before {
            border-radius:4px !important;
            opacity:1!important;
            background-color: var(--v-pink-base) !important;
        }
    }
    
    
    .menuCss {
        background-color: var(--v-primary-base) !important;
        height:100%;
        width:100px;
        overflow: hidden;
        overflow-y: auto;
        background-size: cover;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
        .v-list-item__title {
            z-index:2;
            color:white;
        }
        .menuBtn {
            display:flex;
            height:60px;
            flex-direction: column;
            align-items:center;
            justify-content: center;
            font-size:12px !important;
            .v-icon {
                margin-bottom:5px;
            }
            span {
                font-size:12px;
            }
        }
        
        .openIcon {
            width:100%;
            min-width:40px;
            .v-btn {
                min-width:40px !important;
            }
        }
        .v-navigation-drawer__content::-webkit-scrollbar {
            display: none;
        }
        .v-navigation-drawer__content {
            scrollbar-width: none;
        }
        .v-list-item {
            padding-left:7px !important;
            margin-bottom:0 !important;
            &:first-child {
                margin-top:0 !important;
            }
            // &:hover::before {
            //     border-radius:0px !important;
            //     opacity:1!important;
            //     background-color: rgba(0,0,0,0.3) !important;
            // }
        }
        .v-list-item:not(.v-list-item--active):hover::before {
            border-radius:0px !important;
            opacity:1!important;
            background-color: rgba(0,0,0,0.3) !important;
        }
        .v-list-item__icon {
            display:flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px !important;
        }
        .v-list-item__title {
            font-weight:200 !important;
            font-size:16px !important;
        }
        .active-menu {
            .v-list-item__content {
                z-index:1;
                .v-list-item__title {
                    color:white;
                }
            }
            &::before {
                border-radius:0px !important;
                opacity:1!important;
                background-color: var(--v-pink-base) !important;
            }
        }
        .navigation-content {
            h4 {
                font-weight: 700;
                font-size: 18px;
                padding-bottom:6px;
                padding-top: 15px;
                color:white !important;
            }
        }
        .badge-nb {
            .v-badge__wrapper {
                span {
                    font-size: 10px;
                    height: 15px;
                    min-width: 15px;
                    padding: 3px 4px;
                    font-weight: bold;
                }
            }
        }
    }

</style>
